@layer base {
  .main {
  display: flex;
  align-items: center;
  background-color: initial; /* Spark override */
  font-weight: var(--normal); /* Spark override */
}

.icon {
  padding-right: 0.5rem;
}

}