@layer base {
  .list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.link {
  display: block;
  position: relative;
  padding: var(--spacer-200) 0;
  color: var(--color-black);
  font-size: var(--normalBody);
  line-height: var(--normalBodyLineHeight);
  text-decoration: none;
}

.link--isButton,
.link--isButton:hover {
  display: inline-flex;
  border: none;
  background-color: transparent;
  color: var(--color-black);
  font-family: inherit;
}

.link--isButton::before {
  content: none;
}

.link--active {
  color: var(--mainColor);
  font-weight: var(--bold);
}

.link--active::after,
.list--hasChildrenActive .link {
  border-color: var(--mainColor);
}

.list--isDropdownOpened .link--isButton svg {
  transform: rotate(-180deg) translateX(-8px);
  transform-origin: center;
}

.list--isDropdownOpened .dropdown {
  visibility: visible;
  pointer-events: initial;
}

.dropdown {
  display: grid;
  visibility: hidden;
  grid-template-columns: 1fr;
  gap: var(--spacer-300);
  margin: 0;
  padding: 0;
  transition: 280ms all ease-out;
  list-style: none;
}

.dropdownLink {
  color: var(--color-black);
  font-size: var(--normalBody);
  line-height: var(--normalBodyLineHeight);
  text-decoration: none;
}

@media (--medium-and-less-only) {
  .list {
    flex-direction: column;
  }

  .link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: var(--subheadSmall);
    line-height: var(--subheadSmallLineHeight);
    text-align: left;
  }

  .listItem--active,
  .link--active {
    display: none;
  }

  .link--isButton {
    justify-content: space-between;
  }

  .list--secondary {
    flex-direction: column;
    margin-bottom: var(--spacer-400);
  }

  .listItem {
    border-bottom: thin solid var(--color-grey--100);
  }

  .list--secondary .link {
    font-weight: var(--black);
  }

  .list--secondary .link--active {
    color: var(--color-black);
  }

  .list--secondary .link--active::after {
    content: none;
  }

  .dropdown {
    height: 0;
    overflow: hidden;
  }

  .dropdownLink {
    display: flex;
    align-items: center;
  }

  .dropdownLinkIcon {
    padding-left: var(--spacer-100);
  }

  .dropdownLinkSubtitle {
    display: none;
  }

  .list--isDropdownOpened .dropdown {
    height: initial;
    padding: var(--spacer-100) 0 var(--spacer-400);
  }
}

@media (--large-viewport) {
  .list {
    gap: var(--spacer-100) var(--spacer-300);
  }

  .link {
    font-size: var(--smallBody);
    line-height: var(--smallBodyLineHeight);
    cursor: pointer;
  }

  .link::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid currentcolor;
  }

  .list:not(.list--secondary) .link {
    padding: var(--spacer-100) 0;
  }

  .link:not(.link--active) {
    color: var(--color-white);
  }

  .link:not(.link--active)::after {
    content: none;
  }

  .list--hasChildrenActive .link {
    color: var(--mainColor);
  }

  .list--hasChildrenActive .link::after {
    content: "";
  }

  .list--isDropdownOpened [aria-expanded="true"].link,
  .link:not(.link--active):hover,
  .link:not(.link--active)[aria-current] {
    color: var(--mainColor);
  }

  .list--isDropdownOpened [aria-expanded="true"].link::after,
  .link:hover::after,
  .link:not(.link--active)[aria-current]::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid var(--mainColor);
  }

  .dropdown {
    position: absolute;
    left: 506px;
    gap: var(--spacer-200) calc(var(--spacer-400) * 2);
    width: 100%;
    max-width: 440px;
    padding: var(--spacer-400);
    transform: rotateX(-90deg) translateX(-50%);
    transform-origin: top center;
    opacity: 0.3;
    background-color: var(--color-white);
    box-shadow: var(--default-shadow);
    color: var(--color-black);
  }

  .dropdownLink {
    position: relative;
    font-size: var(--smallBody);
    line-height: var(--smallBodyLineHeight);
  }

  .dropdownLink:hover::after {
    content: "";
    display: block;
    position: absolute;
    z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
    top: -15%;
    left: -2%;
    width: 104%;
    height: 130%;
    border-radius: var(--radius-200);
    background-color: var(--color-grey--050);
  }

  .dropdownLinkIcon {
    display: none;
  }

  .dropdownLinkTitle {
    font-weight: var(--bold);
  }

  .dropdownLinkSubtitle {
    color: var(--color-grey--500);
  }

  .list--isDropdownOpened .dropdown {
    transform: rotateX(0) translateX(-50%);
    opacity: 1;
  }

  .linkIcon {
    display: none;
  }
}

}