@layer base {
  .button > button {
  padding-left: var(--spacer-200);
  background-color: transparent;
  color: var(--color-white);
  font-size: var(--smallBody);
  line-height: var(--smallBodyLineHeight);
  text-transform: uppercase;
}

.button--menu > button {
  padding-left: 0;
  border-color: transparent;
  color: var(--color-black);
}

.button > button:focus {
  border-color: transparent;
}

.button ul button {
  padding: 0;
}

.button a {
  display: block;
  width: 100%;
  padding: var(--spacer-150) var(--spacer-200);
  text-decoration: none;
}

.button--menu a {
  color: var(--color-black);
}

@media (--medium-viewport) {
  .button > button {
    padding: 0;
    color: var(--color-white);
  }

  .button > button:hover::before {
    content: none;
  }

  .button--menu ul {
    /* To avoid positioning outside the width device */
    inset: inherit !important;
    right: 0 !important;
  }
}

}