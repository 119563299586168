@layer base {
  .main {
  --header-padding: 20px;
  --header-maxWidth: var(--large-breakpoint);
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  z-index: 2;
  box-sizing: border-box;
  flex: 0;
  max-width: var(--header-maxWidth);
}

.brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  padding: 0 var(--header-padding);
  background-color: var(--color-black);
}

.brand a {
  display: flex;
  align-items: center;
}

.mainNav {
  flex: 1;
}

.menuButton {
  border: none;
  background-color: transparent;
  color: var(--color-white);
}

.wrapper {
  --height: -56px;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  z-index: 3;
  top: var(--height);
  transition: all 0.3s ease-in-out;
}

.wrapper--fixed {
  position: fixed;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  z-index: 2;
  top: 0;
  width: 100%;
  background: var(--color-black);
}

.wrapper--fixedRemove {
  top: var(--height);
}

.wrapper--fixed .topNav {
  display: none;
}

@media (--medium-and-less-only) {
  .topNav,
  .mainNav,
  .language,
  .login,
  .contact {
    display: none;
    margin: 0 var(--spacer-200);
  }

  .main--open {
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* stylelint-disable-next-line */
    height: 100dvh;
    overflow: auto;
  }

  .main--open .topNav,
  .main--open .mainNav,
  .main--open .language,
  .main--open .login,
  .main--open .contact {
    display: initial;
  }

  .main--open .brand {
    position: sticky;
    z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
    top: 0;
  }

  .main--open .language {
    margin-bottom: var(--spacer-400);
  }

  .main--open .login {
    margin-bottom: var(--spacer-500);
    padding: 0;
  }

  .main--open .contact {
    margin-bottom: var(--spacer-200);
  }

  .topNav {
    margin-top: var(--spacer-200);
    margin-bottom: var(--spacer-800);
  }
}

@media (--large-viewport) {
  .wrapper:not(.wrapper--fixed) {
    background: var(--color-black);
    background: linear-gradient(0deg, var(--color-black) 58.33%, var(--color-grey--700) 58.33%);
  }

  .main {
    --header-maxWidth: var(--large-breakpoint);
    display: grid;
    position: relative;
    grid-template: auto / auto 1fr auto auto;
    grid-template-areas:
      "topNav topNav topNav topNav topNav"
      "logo mainNav language login contact";
    gap: 0 0;
    width: 100%;
    margin: auto;
    padding: 0 var(--spacer-200);
    color: var(--color-white);
  }

  .siteLogo {
    width: 181px;
    height: 32px;
  }

  .mainNav {
    grid-area: mainNav;
    min-height: 56px;
  }

  .topNav {
    position: relative;
    grid-area: topNav;
    min-height: 40px;
  }

  .language {
    display: flex;
    grid-area: language;
    justify-content: flex-end;
    padding-right: var(--spacer-300);
  }

  .login {
    grid-area: login;
    margin-right: var(--sk-space-32);
    margin-left: var(--sk-space-8);
  }

  .contact {
    display: flex;
    grid-area: contact;
    align-items: center;
    justify-content: flex-end;
  }

  .brand {
    position: relative;
    grid-area: logo;
    min-height: inherit;
    padding: 0 56px 0 0;
  }

  .menuButton {
    display: none;
  }
}

@media (--major-viewport) {
  .main {
    --header-maxWidth: var(--major-breakpoint);
  }
}

}