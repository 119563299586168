@layer base {
  .container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  min-height: 100dvh;
  padding: 0;
}

.mainContent {
  display: grid;
  flex: 1;
}

@media (--small-and-less-only) {
  .container--menuOpen {
    max-height: 100vh;
    max-height: 100dvh;
    overflow: hidden;
  }
}

}