@layer base {
  .main {
  padding: var(--spacer-300);
  border-radius: var(--radius-200);
}

[data-theme="purple"].main {
  background-color: var(--color-purple--500);
  background-image: var(--backgroundImage);
  background-repeat: no-repeat;
  background-position: -510px -170px;
}

[data-theme="light"].main {
  color: var(--color-black);
}

[data-theme="dark"].main {
  border: thin solid var(--color-white);
  background-color: var(--color-black);
  color: var(--color-white);
}

[data-theme="dark"].main,
[data-theme="purple"].main {
  color: var(--color-white);
}

[data-theme="dark"].main,
[data-theme="light"].main {
  text-align: center;
}

[data-theme="dark"] .button,
[data-theme="purple"] .button {
  border-color: var(--color-white);
  background-color: var(--color-white);
  color: var(--color-black);
}

[data-theme="light"] .button {
  border-color: var(--color-black);
  background-color: var(--color-black);
  color: var(--color-white);
}

.main a {
  color: inherit;
}

.title {
  /* add this weight in UI-Kit */
  font-weight: var(--black);
}

.title + .text {
  margin-top: var(--spacer-100);
}

.text p {
  margin: 0;
}

@media (--small-and-less-only) {
  .main {
    margin: 0;
  }

  .button {
    margin-top: var(--spacer-300);
  }
}

@media (--medium-viewport) {
  /* For the header desktop, only the button is display */
  .main--menu :not(.buttonWrapper, .button) {
    display: none;
  }

  [data-theme="purple"].main:not(.main--menu) {
    --horizontalyPadding: 44px;
    display: grid;
    grid-template: auto auto / 1fr auto;
    padding: var(--horizontalyPadding) 72px;
    column-gap: var(--horizontalyPadding);
    background-position: -200px -200px;
  }

  [data-theme="light"].main {
    border: thin solid var(--color-grey--050);
  }

  [data-theme="dark"].main,
  [data-theme="light"].main {
    padding: var(--spacer-400);
  }

  [data-theme="dark"] .button,
  [data-theme="light"] .button {
    margin-top: var(--spacer-300);
  }

  .main--menu {
    padding: 0;
    /* For the header desktop, we avoid the preload of the image */
    background-image: none !important;
  }

  .title {
    font-size: var(--headingBig);
    line-height: var(--headingBigLineHeight);
  }

  .title + .text {
    margin-top: var(--spacer-200);
  }

  .text {
    font-size: var(--editoBody);
    line-height: var(--editoBodyLineHeight);
  }

  .title,
  .text {
    grid-column: 1;
  }

  .buttonWrapper {
    display: flex;
    grid-column: 2;
    grid-row: 1 / span 2;
    align-items: center;
    justify-content: center;
  }
}

}